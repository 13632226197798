<template>
  	<div class="layer-popup">
      <div class="pop-wrap" style="width: auto;">
				<div class="pop-inner" style="background: transparent;border-radius: 0px;">
					<div class="pop-head" style="border-bottom: 0px;padding: 0px;display: flex;float: right;">
						<p class="tit"></p>
						<p class="subtit"  @click="close" style="font-size: 11px;line-height: 10px;color: #fff;;">닫기 </p>
						<button type="button" @click="close" class="white-btn" style="border: 0px;"></button>
					</div>
					<div class="pop-cont" style="background:transparent" >
						<img :src="`${publishpath}pub/images/kakaoInfo.png`" alt="" style="width: 100%;height: auto;">
					</div>
				</div>
				
      </div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'KakaoTransInfoPop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
        EventBus.$emit('popupClose')
    },
  },

}
</script>
