<template>
	<div class="layer-popup">
      <div class="pop-wrap">
			<div class="pop-inner">
				<div class="pop-head">
					<p class="tit">샘플문구 선택</p>
					<button type="button" class="close-btn" @click="close"></button>
				</div>
				<div class="pop-cont">
					<div class="txt-con">
						<div class="scroll-area">
							<div class="sample-area">
								<div class="sample-btn-area">
									<button type="btn" :class="['btn', typeFlag===1?'on':'']" @click="typeFlag=1"><span>정중한</span></button>
									<button type="btn" :class="['btn', typeFlag===2?'on':'']" @click="typeFlag=2"><span>간략한</span></button>
									<button type="btn" :class="['btn', typeFlag===3?'on':'']" @click="typeFlag=3"><span>시 구절</span></button>
								</div>
								<div class="sample-con-area">
									<div :class="['con', typeFlag===1?'on':'']">
										<a href="javascript:" v-for="(text,index) in type1Items" :key="index" :class="['sample-list', selectedText===text?'on':'']" @click="selectedText=text">
											<p>{{text}}</p>
										</a>
									</div>
									<div :class="['con', typeFlag===2?'on':'']">
										<a href="javascript:" v-for="(text,index) in type1Items" :key="index" :class="['sample-list', selectedText===text?'on':'']" @click="selectedText=text">
											<p>{{text}}</p>
										</a>
									</div>
									<div :class="['con', typeFlag===3?'on':'']">
										<a href="javascript:" v-for="(text,index) in type1Items" :key="index" :class="['sample-list', selectedText===text?'on':'']" @click="selectedText=text">
											<p>{{text}}</p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-wrap">
					<a href="javascript:" :class="['black-btn', selectedText===''?'disabled':'']" @click="popupEndWordSave"><span>선택 문구 사용</span></a>
				</div>
			</div>
				
      </div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WriteGreetingWordSamplePop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
		typeFlag: 1,

		type1Items:[
			'저희 두 사람 소중한 순간들을 사랑으로 엮어 이제 여러 어른과 친지 앞에서 혼인의 예를 갖춥니다. 바쁘시더라도 부디 오셔서 저희들의 사랑이 더 넓고 깊은 사랑이 될 수 있도록 곁에서 지켜봐 주십시오.',
			'평생을 같이하고 싶은 사람을 만났습니다. 서로 아껴주고 이해하며 사랑 베풀며 살고 싶습니다. 저희 약속 위에 따뜻한 격려로 축복해 주셔서 힘찬 출발의 디딤이 되어 주십시오.',
			'여러분의 지극한 정성과 사랑으로 성장한 두 사람이 성스러운 촛불을 밝히게 되었습니다. 바쁘신 중이라도 부디 참석하셔서 결혼의 첫 걸음을 힘차게 내디딜 수 있도록 축복과 격려를 해 주시면 감사하겠습니다.',
			'결혼은 또 하나의 새로운 인생의 출발이라고 합니다. 오늘이 있기까지 많은 사랑과 관심을 기울여 주시고 이끌어 주신 여러 어른들과 친치 분들을 모시고 저희 두 사람이 백년해로의 진실한 가약을 맺고자 합니다. 부디 참석하시어 저희가 내딛는 새 인생의 첫 걸음을 다사로움과 축복으로 빛내 주시기 바랍니다.'
		],
		type2Items:[
			'저희 두 사람 소중한 순간들을 사랑으로 엮어 이제 여러 어른과 친지 앞에서 혼인의 예를 갖춥니다. 바쁘시더라도 부디 오셔서 저희들의 사랑이 더 넓고 깊은 사랑이 될 수 있도록 곁에서 지켜봐 주십시오.',
			'평생을 같이하고 싶은 사람을 만났습니다. 서로 아껴주고 이해하며 사랑 베풀며 살고 싶습니다. 저희 약속 위에 따뜻한 격려로 축복해 주셔서 힘찬 출발의 디딤이 되어 주십시오.',
			'여러분의 지극한 정성과 사랑으로 성장한 두 사람이 성스러운 촛불을 밝히게 되었습니다. 바쁘신 중이라도 부디 참석하셔서 결혼의 첫 걸음을 힘차게 내디딜 수 있도록 축복과 격려를 해 주시면 감사하겠습니다.',
			'결혼은 또 하나의 새로운 인생의 출발이라고 합니다. 오늘이 있기까지 많은 사랑과 관심을 기울여 주시고 이끌어 주신 여러 어른들과 친치 분들을 모시고 저희 두 사람이 백년해로의 진실한 가약을 맺고자 합니다. 부디 참석하시어 저희가 내딛는 새 인생의 첫 걸음을 다사로움과 축복으로 빛내 주시기 바랍니다.'
		],	
		type3Items:[
			'저희 두 사람 소중한 순간들을 사랑으로 엮어 이제 여러 어른과 친지 앞에서 혼인의 예를 갖춥니다. 바쁘시더라도 부디 오셔서 저희들의 사랑이 더 넓고 깊은 사랑이 될 수 있도록 곁에서 지켜봐 주십시오.',
			'평생을 같이하고 싶은 사람을 만났습니다. 서로 아껴주고 이해하며 사랑 베풀며 살고 싶습니다. 저희 약속 위에 따뜻한 격려로 축복해 주셔서 힘찬 출발의 디딤이 되어 주십시오.',
			'여러분의 지극한 정성과 사랑으로 성장한 두 사람이 성스러운 촛불을 밝히게 되었습니다. 바쁘신 중이라도 부디 참석하셔서 결혼의 첫 걸음을 힘차게 내디딜 수 있도록 축복과 격려를 해 주시면 감사하겠습니다.',
			'결혼은 또 하나의 새로운 인생의 출발이라고 합니다. 오늘이 있기까지 많은 사랑과 관심을 기울여 주시고 이끌어 주신 여러 어른들과 친치 분들을 모시고 저희 두 사람이 백년해로의 진실한 가약을 맺고자 합니다. 부디 참석하시어 저희가 내딛는 새 인생의 첫 걸음을 다사로움과 축복으로 빛내 주시기 바랍니다.'
		],
		selectedText: ''	
		
										


										
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'writeEndWordSamplePop') return;
		context.selectedText =  '';
		context.typeFlag = 1;
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  beforeDestroy(){
  },
  methods: {
	close(){
		
    	EventBus.$emit('popupClose')
	},
	popupEndWordSave(){
		
    	EventBus.$emit('popupEndWordSave',this.selectedText)
    	EventBus.$emit('popupClose')
	}
  },

}
</script>
