<template>
	<div class="layer-popup">
      <div class="pop-wrap">
		<div class="pop-inner">
			<div class="pop-head">
				<p class="tit">워터마크 제거</p>
				<button type="button" class="close-btn"  @click="close"></button>
			</div>
			<div class="pop-cont">
				<div class="txt-con">
					<div class="scroll-area">
						<div class="water-mark-area">
							<div class="top-area">
								<p class="tit">아직 구매하지 않았어요.</p>
								<p class="txt">먼저 스마트스토어에서 구매해 주세요.</p>
								<a href="javascript:" class="naver-btn" @click="popupPurchase"><span>구매하러 가기</span></a>
							</div>
							<div class="bot-area">
								<p class="tit">구매 완료했어요.</p>
								<p class="txt">구매한 네이버 아이디와 연락처를 입력해 주세요.<br />* 구매 확인까지 최대 20분 소요될 수 있습니다.</p>
								<div class="input-wrap">
									<p class="tit">구매한 네이버 아이디</p>
									<div class="input-div">
										<div class="form-input">
											<CustomInput v-model="ordererId" :placeholder="'아이디 입력'"/>
										</div>
										<p class="email">@naver.com</p>
									</div>
								</div>
								<div class="input-wrap">
									<p class="tit">구매한 연락처</p>
									<div class="input-div">
										<div class="form-input hypen">
											<CustomInput v-model="phone1" :placeholder="''"/>
										</div>
										<div class="form-input hypen">
											<CustomInput v-model="phone2" :placeholder="''"/>
										</div>
										<div class="form-input">
											<CustomInput v-model="phone3" :placeholder="''"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btn-wrap"> 
				<a href="javascript:" @click="validationCheck?clickDeleteWaterMark():null" :class="['black-btn',!validationCheck?'disabled':'']"><span>워터마크 제거</span></a>
			</div>
		</div>
				
      </div>
    </div>
</template>


<script>
import CustomInput from "@/components/common/CustomInput.vue";

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WaterMarkDeletePop',
  props:[],
  components: {CustomInput},
  data(){
    return {	
		ordererId:'',
		phone1:'',
		phone2:'',
		phone3:'',
		invitationId:null		
    }
  },
  computed:{
	validationCheck(){
		if(this.isEmpty(this.ordererId)){
			return false;
		}
		if(this.isEmpty(this.phone1)){
			return false;
		}
		if(this.isEmpty(this.phone2)){
			return false;
		}
		if(this.isEmpty(this.phone3)){
			return false;
		}
		return true
	}
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'waterMarkDeletePop') return;

		context.ordererId=''
		context.phone1=''
		context.phone2=''
		context.phone3=''
		context.invitationId = param.invitationId
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  beforeDestroy(){
  },
  methods: {
	close(){
		
    	EventBus.$emit('popupClose')
	},
	clickDeleteWaterMark(){
		const parameter = {
			ordererId: this.ordererId,
			phone: this.phone1+this.phone2+this.phone3,
			invitationId:this.invitationId
		}
		this.$store.dispatch("REQUEST_INVITATION_DELETE_WATERMARK",parameter).then((data) => {
			
				if (data.result) {
    				EventBus.$emit('waterMarkSucess')
    				EventBus.$emit('popupClose')
				} 
		});

	},
	popupPurchase(){
		window.open('https://smartstore.naver.com/vividmare')
	}
  },

}
</script>
