<template>
  <div>
  </div>
</template>


<script>
export default {
  name: 'Footer',
  components: { },
  mounted() {
  },
}
</script>