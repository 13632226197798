<template>
<div class="layer-popup gray">
      <div class="pop-wrap-cropper" >
				<div class="pop-inner">
					<div class="pop-head">
						<button type="button" class="close-btn" @click="close"></button>
					</div>
					<div class="pop-cont">
						<div class="txt-con">
							<div class="scroll-area">
								<cropper v-if="img!=null"
									class="cropper"
									:src="img"
									:stencil-props="{
										aspectRatio: cropRation.x/cropRation.y
									}"
									@change="change"
								/>
							</div>
						</div>
					</div>
					<div class="btn-wrap" >
						<a  @click="regist" :class="'black-btn'" style="color: #fff;">등록</a>
					</div>
				</div>
	  </div>
</div>
	
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import EventBus from "@/utils/CommonEventBus";

export default {
	components: {
		Cropper,
	},
	props:[],
	
    data(){
        return{
			img: null,
			files: null,

			canvas:null,
			cropRation:null,
			type:null
		}
	},
	mounted(){
		
		const context = this
		EventBus.$on("popupInit", (param) => {
			if(param.flag !== 'imageCropPop') return;
			if(param.files!==null && param.files!==undefined){
				context.cropRation = param.cropRation
				context.files = param.files;
				context.type = param.type;
				context.img = URL.createObjectURL(param.files[0]);

			}
		});
	},
	beforeDestroy(){
		this.img = null
		this.files = null
		this.canvas = null
		EventBus.$off('popupInit')
	},
	methods: {
		change({ coordinates, canvas }) {
			this.canvas = canvas
		},
		regist(){
            this.$store.commit('START_LOADING');
			
			this.canvas.toDataURL();
			const context = this
			this.canvas.toBlob(blob => {
				const param ={
					file :new File([blob], context.files[0].name, { type: "image/jpeg", lastModified: Date.now() }),
					type: context.type
				}
    			EventBus.$emit('popupEndCrop',param)
        		EventBus.$emit('popupClose')
			});;
        	this.$store.commit('END_LOADING');

		},
		close(){
			EventBus.$emit('popupClose')
		},
	},
}
</script>

<style scoped>
.cropper {
	background: #DDD;
}
</style>