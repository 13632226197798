<template>
<div class="layer-popup gray">
      <div class="pop-wrap-cropper">
				<div class="pop-inner">
					<div class="pop-head">
						<button type="button" class="close-btn" @click="close"></button>
					</div>
					<div class="pop-cont">
						<div class="txt-con">
							<div class="scroll-area" v-if="urls!=null && urls.length>0" >

								<swiper ref="filterSwiper" 
								:options="swiperOption" 
								role="tablist">
									<swiper-slide role="tab" v-for="(url, index) in urls" :key="index">
										<template v-if="param.type==='grid' && param.subtype === 'complex'">
											
											<input type="radio" v-model="fileValue1[index]" value="short" checked  style="vertical-align:middle;margin-top:-1px;" @change="fileValue1Change($event,index)"/>
											<label for="coffee">short</label>
											<input type="radio" v-model="fileValue1[index]" value="long"  style="vertical-align:middle;margin-top:-1px;" @change="fileValue1Change($event,index)"/>
											<label for="juice">long</label>
											<cropper 
												class="cropper"
												:src="url"
												:stencil-props="{
													aspectRatio:  cropRation[index].x/cropRation[index].y
												}"
												@change="change($event,index)"
											/>


										</template>
										<template v-else>
											<cropper 
												class="cropper"
												:src="url"
												:stencil-props="{
													aspectRatio: cropRation[index].x/cropRation[index].y
												}"
												@change="change($event,index)"
											/>
										</template>
											
									</swiper-slide>
									<div
										class="swiper-pagination"
										slot="pagination"
										>
									</div>
									<div class="swiper-button-prev" slot="button-prev"></div>
									<div class="swiper-button-next" slot="button-next"></div>
								</swiper>
								
								
							</div>
						</div>
					</div>
					<div class="btn-wrap" >
						<a  @click="regist" :class="'black-btn'" style="color: #fff;">등록</a>
					</div>
				</div>
	  </div>
</div>
	
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import EventBus from "@/utils/CommonEventBus";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
const basicRation = () => {
  return {
			x:1,
			y:1.3
			
		}
}
export default {
	components: {
		Cropper,
		swiper,
		swiperSlide
	},
	props:[],
	
    data(){
        return{
			files: null,
			beforeFiles: null,
			urls:null,

			canvas:null,
			type:null,
			fileValue1:[],
			param:null,
			selectedIndex:null,

			cropRation:[],
			 swiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 6, // swiper-slide 사이의 간격 지정
				slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
				slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
				freeMode: false, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
				centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
				allowTouchMove: false,
				pagination: { 
					el: '.swiper-pagination', 
					clickable: true 
				}, 
				navigation: { 
					nextEl: '.swiper-button-next', 
					prevEl: '.swiper-button-prev' 
				} 
			}
		}
	},
	mounted(){
		
		const context = this
		EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'imagesCropPop') return;
			if(param.files!==null && param.files!==undefined){
				context.selectedIndex = param.selectedIndex
				context.fileValue1 = []
				context.cropRation= []
				context.canvas=[]
				context.urls = []
				context.files = param.files;
				context.beforeFiles = param.files;
				context.makeItems(param)
				context.type = param.type;

			}
		});
	},
	beforeDestroy(){
		this.files = null
		this.beforeFiles =null;
		this.canvas = null
		this.cropRation= []
		this.fileValue1= []
		EventBus.$off('popupInit')
	},
	methods: {
		makeItems(param){
			this.param = param;
			for(var i=0;i<this.files.length;i++){
					
				this.fileValue1.push('short')
				if(param.type === 'basic' || param.type === 'thumbnail'){

					this.cropRation.push(basicRation())
				}else{
					if(param.subtype === 'square'){
						this.cropRation.push({x:1,y:1})
					}
					if(param.subtype === 'long'){
						this.cropRation.push({x:1,y:1.3})
					}
					if(param.subtype === 'complex'){
						this.cropRation.push({x:1.3,y:1})
					}
				}
				
				this.urls.push(URL.createObjectURL(this.files[i]))
				this.canvas.push(null);

			}

		},
		change({ coordinates, canvas },index) {
			this.canvas[index] = canvas
		},
		async regist(){
            this.$store.commit('START_LOADING');
			
			const files = []
			const beforeFiles = []
			for(var i=0;i<this.canvas.length;i++){

				var canvas = this.canvas[i];
				canvas.toDataURL();
				const context = this
				const blob = await new Promise(resolve => canvas.toBlob(resolve));

				files.push(new File([blob], context.files[i].name, { type: "image/jpeg", lastModified: Date.now() }));
				beforeFiles.push(new File([context.beforeFiles[i]], context.beforeFiles[i].name, { type: "image/jpeg", lastModified: Date.now() }));

			}
			const paramaeter = {
				files: files,
				beforeFiles: beforeFiles,
				type:this.type,
				fileValue1 : this.fileValue1,
				selectedIndex:this.selectedIndex
			}


			
			EventBus.$emit('popupEndCrops',paramaeter);
			EventBus.$emit('popupClose');
        	this.$store.commit('END_LOADING');

		},
		close(){
			EventBus.$emit('popupClose')
		},
		fileValue1Change(e,index){
			if(e.target.value === 'short'){
				this.cropRation[index] = {x:1.3,y:1}
			}else{
				this.cropRation[index] = {x:1,y:1.3}
			}
		}
	},
}
</script>

<style scoped>
</style>