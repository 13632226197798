<template>
  	<div class="layer-popup">
      <div class="pop-wrap" >
				<div class="pop-inner">
					<div class="pop-head">
						<p class="tit">참석의사 집계결과</p>
						<button type="button" @click="close" class="close-btn"></button>
					</div>
					<div class="pop-cont" id="attendNumPopCont" >
						<div class="txt-con">
							<div class="scroll-area">
								<div class="attend-num-area">
									<div class="bg"></div>
									<div class="top-area" >
										<div class="attend-box possiable">
											<div class="total">
												<p class="tit">참석 가능</p>
												<p class="num">{{possibleCnt}}</p>
											</div>
											<div class="num-wrap">
												<div class="num-div">
													<p class="tit">신랑</p>
													<p class="num">{{getInvitationAttendStatic.gooomPossibleCnt}}</p>
												</div>
												<div class="num-div">
													<p class="tit">신부</p>
													<p class="num">{{getInvitationAttendStatic.bridePossibleCnt}}</p>
												</div>
											</div>
										</div>
										<div class="attend-box impossiable">
											<div class="total">
												<p class="tit">참석 불가능</p>
												<p class="num">{{imPossibleCnt}}</p>
											</div>
											<div class="num-wrap">
												<div class="num-div">
													<p class="tit">신랑</p>
													<p class="num">{{getInvitationAttendStatic.gooomImpossibleCnt}}</p>
												</div>
												<div class="num-div">
													<p class="tit">신부</p>
													<p class="num">{{getInvitationAttendStatic.brideImpossibleCnt}}</p>
												</div>
											</div>
										</div>
										<div class="attend-box full" v-if="invitation.invitationAttendEatYn === 'Y'">
											<div class="left">
												<p class="tit">식사함</p>
												<p class="num">{{getInvitationAttendStatic.eatCnt}}</p>
											</div>
											<div class="right">
												<p class="tit">식사안함</p>
												<p class="num impossiable">{{getInvitationAttendStatic.noEatCnt}}</p>
											</div>
										</div>
									</div>
									<div class="answer-area">
										<div class="type-area">
											<p class="tit">전체 응답 수 <span>{{getInvitationAttendStatic.responseCnt}}</span></p>
											<div class="type">
												<p class="tit">하객 분류</p>
												<div class="type-btn">
													<button type="button" :class="['btn',groomBrideDistibute==='all'?'on':'']" @click="groomBrideDistibute='all'"><span>전체</span></button>
													<button type="button" :class="['btn',groomBrideDistibute==='groom'?'on':'']" @click="groomBrideDistibute='groom'"><span>신랑측</span></button>
													<button type="button" :class="['btn',groomBrideDistibute==='bride'?'on':'']" @click="groomBrideDistibute='bride'"><span>신부측</span></button>
												</div>
											</div>
											<div class="type">
												<p class="tit">참석 여부</p>
												<div class="type-btn">
													<button type="button" :class="['btn',attendDistribute==='all'?'on':'']" @click="attendDistribute='all'"><span>전체</span></button>
													<button type="button" :class="['btn',attendDistribute==='Y'?'on':'']" @click="attendDistribute='Y'"><span>참석 가능</span></button>
													<button type="button" :class="['btn',attendDistribute==='N'?'on':'']" @click="attendDistribute='N'"><span>참석 불가</span></button>
												</div>
											</div>
										</div>
										<div class="answer-list">
											<template v-for="(attend,index) in getInvitationAttendList">
												<div v-if="(attendDistribute==='all' || attendDistribute === attend.invitationAttendPossibilityYn) && (groomBrideDistibute==='all' || groomBrideDistibute === attend.invitationAttendGroomBride)" class="list"  :key="index">
													<div class="top-area">
														<p :class="['label',attend.invitationAttendGroomBride === 'groom'? 'blue':'red']">{{attend.invitationAttendGroomBride === 'groom'? '신랑측':'신부측'}}</p>
														<p class="tit"><span>{{attend.invitationAttendName}}</span>님 {{attend.invitationAttendCompanionCount>0?`외 ${attend.invitationAttendCompanionCount}명`:''}}</p>
														<p v-if="attend.invitationAttendPossibilityYn ==='Y'" class="attend possiable">참석가능</p>
														<p v-else class="attend impossiable">참석불가</p>
													</div>
													<div  v-if="attend.invitationAttendPossibilityYn ==='Y'" class="bot-area">
														<div class="con" v-if="invitation.invitationAttendNumberYn === 'Y'">
															<p class="tit">연락처</p>
															<p class="txt">{{attend.invitationAttendNumber}}</p>
														</div>
														<div class="con" v-if="invitation.invitationAttendEatYn === 'Y'">
															<p class="tit">식사여부</p>
															<p class="txt">식사함</p>
														</div>
														<div class="con" v-if="invitation.invitationAttendManCountYn === 'Y'">
															<p class="tit">동행인</p>
															<p class="txt">{{attend.invitationAttendCompanionCount>0?attend.invitationAttendCompanionCount:'-'}}</p>
														</div>
														<div class="con" v-if="invitation.invitationAttendMessageYn === 'Y'">
															<p class="tit">메시지</p>
															<p class="txt">{{attend.invitationAttendMessage}}</p>
														</div>
													</div>
													<div v-else class="bot-area">
														<div class="con" v-if="invitation.invitationAttendNumberYn === 'Y'">
															<p class="tit">연락처</p>
															<p class="txt">{{attend.invitationAttendNumber}}</p>
														</div>
														<div class="con" v-if="invitation.invitationAttendMessageYn === 'Y'">
															<p class="tit">메시지</p>
															<p class="txt">{{attend.invitationAttendMessage}}</p>
														</div>
													</div>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
      </div>
    </div>
</template>


<script>
import { mapGetters } from "vuex";

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'PostPop',
  props:[],
  components: {},
  computed:{
      ...mapGetters(['getInvitationAttendList','getInvitationAttendStatic']),
	  possibleCnt(){
		return (this.isEmpty(this.getInvitationAttendStatic.gooomPossibleCnt)?0:this.getInvitationAttendStatic.gooomPossibleCnt)
		 + (this.isEmpty(this.getInvitationAttendStatic.bridePossibleCnt)?0:this.getInvitationAttendStatic.bridePossibleCnt)
	  },
	  imPossibleCnt(){

		return (this.isEmpty(this.getInvitationAttendStatic.gooomImpossibleCnt)?0:this.getInvitationAttendStatic.gooomImpossibleCnt)
		 + (this.isEmpty(this.getInvitationAttendStatic.brideImpossibleCnt)?0:this.getInvitationAttendStatic.brideImpossibleCnt)

	  }
  },
  data(){
    return {
      invitationAttend:{
		
		invitationId:null
	  },
	  invitation:{},
	  
	  attendDistribute:'all',
	  groomBrideDistibute:'all'
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'attendResultPop') return;
		context.invitation = param.invitation
      context.invitationAttend.invitationId = param.invitation.invitationId
	  const parameter = {
		invitationAttend: context.invitationAttend
	  }
	  context.$store.dispatch('REQUEST_LIST_INVITATION_ATTEND',parameter)
      $(".attend-num-area > .top-area").css("width", $(".attend-num-area .bg").width())
	});
  },
  beforeDestroy(){
	this.invitationAttend = {invitationId:null}
	this.$store.dispatch('REQUEST_LIST_INVITATION_ATTEND_INIT')
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
		this.invitation={}
		this.attendDistribute='all'
		this.groomBrideDistibute='all'
		this.invitationAttend = {invitationId:null}
		this.$store.dispatch('REQUEST_LIST_INVITATION_ATTEND_INIT')
        EventBus.$emit('popupClose')
    },
  },

}
</script>
