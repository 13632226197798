<template>
  
	<header v-if="!getPreviewFlag" :class="$route.path!=='/adminInvitationModify'?'theme2':'theme'">
		<div class="inner">
			<div class="left">
				<h1><a href="javascript:" @click="$router.push('/')" class="logo"></a></h1>
			</div>
			<div class="right">
				<p class="name"></p>
						<a href="javascript:" class="preview-btn" @click="clickPreview"><span>미리보기</span></a>
						<a href="javascript:" class="save-btn" @click="clickInvitationSave"><span>저장하기</span></a>
			</div>
		</div>
	</header>

</template>


<script>
import { mapGetters } from "vuex";
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'Header',
  components: { },
  computed:{
      ...mapGetters(['getPreviewFlag']),
  },
  data(){
    return {
      previewFlag:false				
    }
  },
  mounted() {

  },
  methods:{
    clickInvitationSave(){
      EventBus.$emit('clickInvitationSave')
    },
    clickPreview(){
      this.$store.commit("PREVIEW_CLICK_BTN",true)

    }
  }
}
</script>