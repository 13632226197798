<template>
  
	<header v-if="!getPreviewFlag" :class="$route.path!=='/write'?'theme2':'theme'">
		<div class="inner">
			<div class="left">
				<h1><a href="javascript:" @click="$router.push('/')" class="logo"></a></h1>
			</div>
			<div class="right">
				<p class="name"><span>{{getUserInfo.name}}</span>님</p>
        <template v-if="$route.path!=='/write'">
					<a href="javascript:" class="product-btn" @click="logout">로그아웃</a>
          <a href="javascript:" class="black-btn" @click="$router.push('/write')"><span>청첩장 만들기</span></a>
        </template>
        <template v-else>
						<a href="javascript:" class="product-btn" @click="$router.push('/')">제작내역</a>
						<a href="javascript:" class="preview-btn" @click="clickPreview"><span>미리보기</span></a>
						<a href="javascript:" class="save-btn" @click="clickInvitationSave"><span>저장하기</span></a>
        </template>
			</div>
		</div>
	</header>

</template>


<script>
import { mapGetters } from "vuex";
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'Header',
  components: { },
  computed:{
      ...mapGetters(['getUserInfo','getPreviewFlag']),
  },
  data(){
    return {
      previewFlag:false				
    }
  },
  mounted() {
    this.$store.dispatch('REQUEST_NAVER_PROFILE')

  },
  methods:{
    logout(){
        this.$store.dispatch("REQUEST_LOGOUT")
    },
    clickInvitationSave(){
      EventBus.$emit('clickInvitationSave')
    },
    clickPreview(){
      this.$store.commit("PREVIEW_CLICK_BTN",true)

    }
  }
}
</script>