<template>
	<div class="layer-popup gray">
      	<div class="pop-wrap" >
			<div class="pop-inner" style="height:90%">
				<div class="pop-head">
					<button type="button" class="close-btn" @click="close()"></button>
				</div>
				<div class="pop-cont">
					<div id="popup-wrap" ref="empopup" style="height:100%;width:100%" >
						<!-- 우편번호 API가 띄워질 공간 -->
					</div>

				</div>
				
			</div>
      	</div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'PostPop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
      zip: '',
      addr1: '',
      addr2: '',
      loadAddress: ''					
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'postPop') return;
		context.showApi();
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
	close(){
    	EventBus.$emit('popupClose')
	},
	showApi() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          debugger
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
            // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            this.loadAddress = data.roadAddress;
            let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
            let extraRoadAddr = ''; // 도로명 조합형 주소 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }
            // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
            if(fullRoadAddr !== ''){
                fullRoadAddr += extraRoadAddr;
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.zip = data.zonecode; //5자리 새우편번호 사용
            this.addr1 = fullRoadAddr;

            
        
            EventBus.$emit("searchAddress", data.address);
    		EventBus.$emit('popupClose')
        },
        width : '100%',
        height : '100%',
        maxSuggestItems : 5
      }).embed(this.$refs.empopup)
    }
  },

}
</script>
