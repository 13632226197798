<template>
  <div id="wrap">
      <Header v-if="getToken!==null && getToken !==undefined"/>
      <NonLoginHeader v-else/>
        <transition  name="fade-transform" mode="out-in">
            <router-view :key="$route.path"></router-view>
        </transition>
        <div class="dimd"></div>
      <Footer/>
      
		<WriteGreetingWordSamplePop v-show="popupFlag === 'writeGreetingWordSamplePop'"/>
		<WriteEndWordSamplePop v-show="popupFlag === 'writeEndWordSamplePop'"/>
		<ContactPop v-show="popupFlag === 'contactPop'"/>
		<PostPop v-show="popupFlag === 'postPop'"/>
		<AttendPop1 v-show="popupFlag === 'attendPop1'"/>
		<AttendPop2 v-show="popupFlag === 'attendPop2'"/>
		<AttendPop3 v-show="popupFlag === 'attendPop3'"/>
		<AttendPop4 v-show="popupFlag === 'attendPop4'"/>
		<ImageCropPop v-show="popupFlag === 'imageCropPop'" />
		<ImagesCropPop v-show="popupFlag === 'imagesCropPop'" />
		<WaterMarkDeletePop v-show="popupFlag === 'waterMarkDeletePop'" />
    
		<AttendResultPop v-show="popupFlag === 'attendResultPop'"/>
    
		<GuestBookWritePop v-show="popupFlag === 'guestBookWritePop'"/>
		<GuestBookDeletePop v-show="popupFlag === 'guestBookDeletePop'"/>
		<KakaoTransInfoPop v-show="popupFlag === 'kakaoTransInfoPop'"/>
		<ImageExtendsViewPop v-show="popupFlag === 'imageExtendsViewPop'"/>
  </div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import WriteGreetingWordSamplePop from "@/components/popup/WriteGreetingWordSamplePop.vue";
import WriteEndWordSamplePop from "@/components/popup/WriteEndWordSamplePop.vue";
import ContactPop from "@/components/popup/ContactPop.vue";
import PostPop from "@/components/popup/PostPop.vue";
import AttendPop1 from "@/components/popup/AttendPop1.vue";
import AttendPop2 from "@/components/popup/AttendPop2.vue";
import AttendPop3 from "@/components/popup/AttendPop3.vue";
import AttendPop4 from "@/components/popup/AttendPop4.vue";
import ImageCropPop from "@/components/popup/ImageCropPop.vue";
import ImagesCropPop from "@/components/popup/ImagesCropPop.vue";
import WaterMarkDeletePop from "@/components/popup/WaterMarkDeletePop.vue";
import AttendResultPop from "@/components/popup/AttendResultPop.vue";
import GuestBookWritePop from "@/components/popup/GuestBookWritePop.vue";
import GuestBookDeletePop from "@/components/popup/GuestBookDeletePop.vue";
import KakaoTransInfoPop from "@/components/popup/KakaoTransInfoPop.vue";
import ImageExtendsViewPop from "@/components/popup/ImageExtendsViewPop.vue";

import { mapGetters } from "vuex";
import Header from '@/components/common/Header.vue'
import NonLoginHeader from '@/components/common/NonLoginHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  name: 'App',
  components: { Footer, Header, NonLoginHeader ,WriteGreetingWordSamplePop,WriteEndWordSamplePop,ContactPop,PostPop,AttendPop1,AttendPop2,AttendPop3,AttendPop4,ImageCropPop,ImagesCropPop,WaterMarkDeletePop,AttendResultPop,GuestBookWritePop,GuestBookDeletePop,KakaoTransInfoPop,ImageExtendsViewPop},
  data(){
    return {
		popupFlag:''
    }
  },
  mounted() {
	const context = this
    EventBus.$on("layerPopup", (param) => {
      EventBus.$emit('popupInit',param);
		  context.popupFlag =  param.flag;
	});
    EventBus.$on("popupClose", () => {
		  context.popupFlag =  null;
	});
  
  },
  computed:{
      ...mapGetters(['getToken']),
  },
  beforeDestroy(){
    EventBus.$off('layerPopup')
    EventBus.$off('popupClose')
  },
}
</script>