<template>
	<div class="layer-popup">
      <div class="pop-wrap">
			<div class="pop-inner">
				<div class="pop-head">
					<p class="tit">샘플문구 선택</p>
					<button type="button" class="close-btn" @click="close"></button>
				</div>
				<div class="pop-cont">
					<div class="txt-con">
						<div class="scroll-area">
							<div class="sample-area">
								<div class="sample-btn-area">
									<button type="btn" :class="['btn', typeFlag===1?'on':'']" @click="typeFlag=1"><span>정중한</span></button>
									<button type="btn" :class="['btn', typeFlag===2?'on':'']" @click="typeFlag=2"><span>간략한</span></button>
									<button type="btn" :class="['btn', typeFlag===3?'on':'']" @click="typeFlag=3"><span>시 구절</span></button>
								</div>
								<div class="sample-con-area">
									<div :class="['con', typeFlag===1?'on':'']">
										<a href="javascript:" v-for="(text,index) in type1Items" :key="index" :class="['sample-list', selectedText===text?'on':'']" @click="selectedText=text">
											<p v-html="getLineReplace(text)"></p>
										</a>
									</div>
									<div :class="['con', typeFlag===2?'on':'']">
										<a href="javascript:" v-for="(text,index) in type2Items" :key="index" :class="['sample-list', selectedText===text?'on':'']" @click="selectedText=text">
											<p v-html="getLineReplace(text)"></p>
										</a>
									</div>
									<div :class="['con', typeFlag===3?'on':'']">
										<a href="javascript:" v-for="(text,index) in type3Items" :key="index" :class="['sample-list', selectedText===text?'on':'']" @click="selectedText=text">
											<p v-html="getLineReplace(text)"></p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-wrap">
					<a href="javascript:" :class="['black-btn', selectedText===''?'disabled':'']" @click="popupSave"><span>선택 문구 사용</span></a>
				</div>
			</div>
				
      </div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WriteGreetingWordSamplePop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
		typeFlag: 1,

		type1Items:[
			'저희 두 사람\n소중한 순간들을 사랑으로 엮어\n이제 여러 어른과 친지 앞에서 혼인의 예를 갖춥니다.\n바쁘시더라도 부디 오셔서 저희들의 사랑이\n더 넓고 깊은 사랑이 될 수 있도록\n곁에서 지켜봐 주십시오. ',
			'평생을 같이하고 싶은 사람을 만났습니다. \n서로 아껴주고 이해하며 사랑 베풀며 살고 싶습니다.\n저희 약속 위에 따뜻한 격려로 축복해 주셔서\n힘찬 출발의 디딤이 되어 주십시오. ',
			'여러분의 지극한 정성과\n사랑으로 성장한 두 사람이\n성스러운 촛불을 밝히게 되었습니다.\n바쁘신 중이라도 부디 참석하셔서\n결혼의 첫 걸음을 힘차게 내디딜 수 있도록\n축복과 격려를 해 주시면 감사하겠습니다. ',
			'결혼은 또 하나의 새로운 인생의 출발이라고 합니다.\n오늘이 있기까지 많은 사랑과 관심을 기울여 주시고\n이끌어 주신 여러 어른들과 친치 분들을 모시고\n저희 두 사람이 백년해로의 진실한 가약을 맺고자 합니다.\n부디 참석하시어 저희가 내딛는 새 인생의 첫 걸음을\n다사로움과 축복으로 빛내 주시기 바랍니다. ',
			'저희 두 사람이 사랑과 믿음으로 \n한 가정을 이루게 되었습니다.\n바쁘시더라도 부디 오셔서\n저희들의 앞날을 축복해주시고\n격려해 주시면 더 없는 기쁨이 되겠습니다. ',
			'두 사람이 사랑으로 만나\n진실과 이해로써 하나를 이루려 합니다.\n이 두 사람을 지성으로 아끼고 돌봐주신 \n여러 어른들과 친지를 모시고\n서약을 맺고자 하오니 바쁘신 가운데 두 사람의 장래를\n가까이에서 축복해 주시면 고맙겠습니다. ',
			'두 사람이 만나 하나의 매듭이 되고\n하나의 길이 되어 하나의 보금자리를 이루려 합니다. \n변함없는 믿음과 사랑으로 축복해 주십시오.',
			'오랜 기다림 속에서 저희 두 사람,\n한 마음 되어 참된 사랑의 결실을 맺게 되었습니다. \n오셔서 축복해 주시면 큰 기쁨이겠습니다. ',
			'진실과 이해로써 만난 두 사람이\n사랑으로써 하나를 이루려 합니다. \n그동안 이 두 사람을 지성으로 보살펴 주신\n여러 어른과 친지를 모시고 하나되는 서약을 맺고자 하오니\n바쁘신 가운데 찾아 주시어 두 사람의 장래를\n가까이에서 축복해 주시면 감사하겠습니다. ',
			'항상 베풀어주신 은혜에\n감사드리며 부족한 저희들이\n이제 진실과 이해로써 하나를 이룹니다. \n부디 오셔서 두 사람의 사랑이 더 큰 사랑이\n될 수 있도록 축복해 주시면 감사하겠습니다. ',
			'저희 두 사람이\n이제 믿음과 사랑으로\n한 가정을 이루게 되었습니다.\n부디 오셔서 축복해 주시기 바랍니다.',
			'새로운 마음과 새 의미를 간직하며\n저희 두 사람이 새 출발의 첫 걸음을 내딛습니다.\n좋은 꿈, 바른 뜻으로 올바르게 살 수 있도록\n축복과 격려주시면 더 없는 기쁨으로 간직하겠습니다. ',
			'아름다운 꽃에는 가꾸어 온 사람의 노고가 깃들어 있듯이\n인생의 기쁜 순간에 있어 도와주신 분들에 대한 고마움을 느낍니다. \n언제나 이러한 고마움을 잊지 않고 사랑하며 살겠다는\n소중한 맹세의 시간을 갖고자 합니다. \n새로이 함께 서는 저희 두 사람의 모습을 부디 오셔서\n지켜봐 주시기 바랍니다. ',
			'저희들의 오늘이 있기까지 보내주신 따뜻한 사랑과\n깊은 관심에 진심으로 감사하오며,\n저희 두 사람은 여러분의 축복을 받으며\n진실한 가약을 맺고자 합니다. \n부디 참석하시어 기쁨의 자리를 축복으로\n더욱 빛내 주시 길 바라옵니다. ',
			'서로가 마주 보며 다져온 사랑을\n이제 함께 한 곳을 바라보며 걸어갈 수 있는\n큰 사랑으로 키우고자 합니다.\n저희 두 사람이\n사랑의 이름으로 지켜 나갈 수 있게\n앞날을 축복해 주시면\n감사하겠습니다. ',
			'새로운 마음과 새 의미를 간직하며,\n저희 두 사람이 새 출발의\n첫 걸음을 내딛습니다.\n좋은 꿈, 바른 뜻으로 올바르게 살 수 있도록\n축복과 격려 주시면 더 없는 기쁨으로\n간직하겠습니다. ',

			
		],
		type2Items:[
			'햇살이 눈부신 10월의\n어느 날…\n저희, 결혼합니다.',
			'뜻깊은 자리에 소중한\n분들을 초대합니다.',
			'00와 00가\n햇살이 아름다운 시월\n사랑의 서약을 맺습니다.',
			'같은 공간에서 같은 미래를\n꿈꿀 수 있는 사람을 만났습니다.',
			'000 ˚ 000\n시작하는 날에\n소중한 분들을 초대합니다.',
			'두 사람, 첫 마음 그대로\n서로 사랑하며 살겠습니다.',
			'우리 두 사람\n이제 하나가 됩니다.',
			'저희, 결혼합니다.',
			'행복과 사랑을 담아\n하나의 마음으로 시작하는\n두 사람을 축복해주세요.',
			'함께라는 이름으로 믿어주고\n사랑하는 부부가 되겠습니다.\n우리 이제, 평생 함께',
			'우리 두 사람, 서로에게 꽃처럼\n아름답고 향기로운 사람이 되겠습니다.',
			'눈부시게 아름다운 날에',
			'두 사람, 서로만을 아끼고 사랑하며\n영원히 함께 할 것을 다짐합니다.',
			'반짝반짝 빛나는\n우리들의 아름다운 시작을\n함께 축하해주세요.',
			'인생에서 가장 찬란한 순간\n두 사람 하나 됨을 약속합니다.',
			'우리 두 사람, 반짝이는 날에\n아름다운 결혼을 합니다.',
			'찬란하게 아름다운 날\n우리 두 사람 하나가 됩니다.',
			'서로 맞잡은 두 손 놓지 않고\n영원히 함께 할 것을 약속합니다.',
			'꽃처럼 아름다운 날\n두 사람 하나가 되려 합니다.',
			'가장 아름다운 날\n사랑의 서약을 합니다.',
			'아름다운 날\n두 사람 결혼합니다.',
			'찬란하게 아름다운 날\n우리 두 사람\n하나 됨을 약속합니다.',
            '저희 두 사람\n가장 아름다운 날\n사랑의 약속을 합니다.',
            '서로를 사랑했던\n첫 마음 잊지 않고\n행복하게 잘 살겠습니다. ',
            '든든한 버팀목이 되어\n평생 함께 하겠습니다.',
            '오래 바라볼수록\n서로 더 아끼고 사랑하는\n아름다운 부부가 되겠습니다. ',
            '가장 아름답고 행복한 순간,\n저희 결혼합니다. ',
            '눈부시게 아름다운 날,\n우리 두 사람',
            '함께\n모든 순간\n모든 날',
		],	
		type3Items:[
			'봄 물보다 깊으니라\n가을 산보다 높으니라\n달보다 빛나리라\n돌보다 굳으리라\n사랑을 묻는 이 있거든\n이대로 말하리\n\n한용운, 사랑',
			'예쁜 예감이 들었다.\n우리는 언제나 손을 \n잡고 있게 될 것이다.\n\n이이체, 연인',
			'너의 얼굴 바라봄이 반가움이다\n너의 목소리 들음이 고마움이다\n너의 눈빛 스침이 끝내 기쁨이다\n끝끝내\n너의 숨소리 듣고 네 옆에\n내가 있음이 그냥 행복이다\n\n나태주, 끝끝내',
			'예쁘지 않은 것을\n예쁘게 보아주는 것이 사랑이다\n좋지 않을 것을\n좋게 생각해 주는 것이 사랑이다\n싫은 것도 잘 참아주면서\n처음만 그런 것이 아니라\n나중에 아주 나중까지\n그렇게 하는 것이 사랑이다\n\n나태주, 사랑에 답함',
			'많은 것을 알기를\n꿈꾸지 않는다\n\n다만 지금, 여기\n내 앞에서 웃고 있는 너\n그것이 내가 아는 세상의\n전부이기를 바란다.\n\n나태주, 소망',
			'함께 가자 먼 길\n너와 함께 라면 멀어도 가깝고\n아름답지 않아도 아름다운 길\n나도 그 길 위에서 나무가 되고\n너를 위해 착한 바람이 되고 싶다.\n\n나태주, 먼 길',
			'사랑은 소유가 아니라 동햄임을 아는 두 사람은\n잡은 손을 놓지 않되 함부로 잡아끌지 않을 것이며\n서로의 두 눈을 고요히 바라보아\n말하지 않아도 같은 쪽으로 걸어가리라\n\n\n박미라, 아름다운 날에 부치다',
			'생의 끝 날 당신 손을 잡고 웃음짓기를\n그 바람에 이내 참을 수 없어 청혼합니다\n어여쁜 당신, 나와 결혼합시다\n\n오승아, 법대로 사랑하라',
			'봄의 그대는 벚꽃이었고\n여름의 그대는 바람이었으며\n가을의 그대는 하늘이고\n겨울의 그대는 하얀 눈이었다.\n\n그대는 언제나\n행복 그 자체였다.\n\n강현욱, 사계',
			'웃어도 예쁘고\n웃지 않아도 예쁘고\n눈을 감아도 예쁘다\n오늘은 네가 꽃이다.\n\n나태주, 오늘의 꽃',
			'서로의 이름을 부르는 것만으로도\n사랑의 깊이를 확인할 수 있는 두 사람이\n꽃과 나무처럼 걸어와서\n서로의 모든 것이 되기 위해\n오랜 기다림 끝에 혼례식을 치르는 날\n세상은 더욱 아름다워라\n\n이해인, 사랑의 사람들이여',
			'어제 거기가 아니고\n내일 저기도 아니고\n다만 오늘 여기\n그리고 당신.\n\n나태주, 행복',
			'장담하건대, 세상이 다 겨울이어도\n우리 사랑은 늘 봄처럼 따뜻하고\n간혹 여름처럼 뜨거울 겁니다.\n\n이수동, 사랑가',
			'이토록 아름다운 날에\n이토록 어여쁜 너가 있으니\n이토록 난 행복할 수 밖에\n\n이경선, 봄 밤',
			'인생은 누구나 비슷한 길을 걸어간다\n결국엔 늙어서 지난 일을 추억하는 것 뿐이다\n결혼은 따뜻한 사람이랑 하거라\n\n영화-어바웃타임 대사 中',
			'우리가 서로 뜨겁게 사랑한다는 것은\n그대는 나의 세상을\n나는 그대의 세상을\n함께 짊어지고 새벽을 향해\n걸어가겠다는 것입니다.\n\n안도현, 사랑한다는 것',
			'그리운 날은 그림을 그리고\n쓸쓸한 날은 음악을 들었다.\n그리고 남는 날은\n너를 생각해야만 했다.\n\n나태주, 사는 법',
		],
		selectedText: ''	
		
										


										
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'writeGreetingWordSamplePop') return;
		context.selectedText =  '';
		context.typeFlag = 1;
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  beforeDestroy(){
  },
  methods: {
	close(){
		
    	EventBus.$emit('popupClose')
	},
	popupSave(){
		
    	EventBus.$emit('popupSave',this.selectedText)
    	EventBus.$emit('popupClose')
	}
  },

}
</script>
